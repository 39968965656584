export const content = `
# A simple Kubernetes tutorial

![Suicide Forest](https://d31ofspcjjwsfk.cloudfront.net/a-simple-k8s-tutorial/background.jpg)

Hello! This post is a simple Kubernetes tutorial. I condensed 


SSH into the master node and install microk8s in strict mode

\`\`\`bash
sudo snap install microk8s --channel 1.25-strict/stable
\`\`\`

Perhaps, in my next post, I will share how to run an Kubeflow pipeline. Thats all for now :)

See you in the next one !
`;