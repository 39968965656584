export const content = `
# How did I built this blog ?

![Tasmania](https://d31ofspcjjwsfk.cloudfront.net/how-to-build-this-blog/background.jpg)

Hello! This is the first post of the blog. The primary purpose behind crafting this blog 
is to share information about what I am creating or learning. This can be related to DevOps, 
Cloud and Rust. 

The first post will be about how I build the blog. I used tools 

- React.js
- Typescript
- Tailwind
- ReactMarkdown
- Vercel

That's all for now. See you in the next one !

`;