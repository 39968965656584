import './App.css';
import HomePage from './pages/home';
import AboutPage from './pages/about';
import Header from './components/header';
import Footer from './components/footer';
import ArticlePage from './pages/article';
import { Routes, Route } from 'react-router-dom';
import { data_2023, data_2024 } from './data'

function App() {
  return (
    <div className = "flex flex-col justify-start m-auto min-h-screen w-full max-w-3xl py-0 px-7 relative">
      <Header/>
      <Routes>
        <Route path='/' element={<HomePage articles={{ articles_2023: data_2023, articles_2024: data_2024 }} />} />
        <Route path='/about' element={ <AboutPage/> } />
        <Route path='/:id' element={ <ArticlePage/> } />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
