import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type ArticleProps = {
  markdown: string
}

const Article = ({ markdown } : ArticleProps) => {
  return (
    <article className="prose prose-sm sm:prose">
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
        {markdown}
      </ReactMarkdown>
    </article>
  );
}

export default Article;
