import { Link, useLocation } from "react-router-dom";

const activeLink = 'text-sm text-rust'
const inactiveLink = 'text-sm'

function Header() {
  const location = useLocation();
  return (
    <div className='w-100 flex items-center justify-between py-10' aria-label='navigation'>
        <a href='/' className='-m-1.5 p-1.5'>
          <span className='text-2xl font-bold cursor-pointer'>Kai Kiat Poh.</span>
        </a>
      <div className='flex items-center'>
      <Link to='/' className = {location.pathname === '/' ? activeLink: inactiveLink} style = {{'margin': '0 15px'}}>
        Blog
      </Link>
      <Link to='/about' className = {location.pathname === '/about' ? activeLink: inactiveLink} style = {{'margin': '0 15px'}}>
        About
      </Link>
      </div>
    </div>
  );
}

export default Header;
