export const content = `
# Deploying Kubeflow using Kubernetes

![Tasmania view](https://d31ofspcjjwsfk.cloudfront.net/deploying-kubeflow-using-k8s/background.jpg)

Hello! This post is about deploying Kubeflow using Kubernetes. Initially, I thought that it will be straightforward.
However, it turn out harder than expected, there were alot of issues provisioning with GKE and DigitalOcean's managed Kubernetes
does not support kubeflow.

After 3 days of intense research (Yes I gave up quickly...), I decided to make use of DigitalOcean's droplets and microk8s to host my Kubernetes cluster.

I used 1 master node and 2 worker nodes. Here are the specifications of each node

- Ubuntu v22.04
- Regular SSD
- 8GB, 4CPUs
- 160GB Storage

SSH into the master node and install microk8s in strict mode

\`\`\`bash
sudo snap install microk8s --channel 1.25-strict/stable
\`\`\`

Install the required plugins with
\`\`\`bash
microk8s enable dns storage ingress metallb:10.64.140.43-10.64.140.49
\`\`\`


Now run, this will print a join node command, which needs to be executed on the worker node.
\`\`\`bash
microk8s add-node
\`\`\`

If the worker node is unable to reach the master node, this could be because the master's node IP address is not registered in /etc/host.


Next install juju, the version needs to be <= 3.16, then bootstrap a K8s cluster.

\`\`\`bash
mkdir /root/.local/share/juju
snap install --channel=3.1 juju

juju bootstrap microk8s
\`\`\`


After bootstrapping, run "kubectl get pods", make sure that all pods are healthy

![kubectl get pods](https://d31ofspcjjwsfk.cloudfront.net/deploying-kubeflow-using-k8s/kgp.png)

Next, set up kubeflow, this step took 40 minutes to complete
\`\`\`bash
juju add-model kubeflow
juju deploy kubeflow --trust
\`\`\`

Install certmanager using Helm charts
\`\`\`bash
microk8s helm3 repo add jetstack https://charts.jetstack.io
microk8s helm3 install cert-manager jetstack/cert-manager --namespace cert-manager --create-namespace --set installCRDs=true
\`\`\`

Now, purchase any domain from any DNS provider such as GoDaddy. Purchase a domain name. Create an A record pointing to the IP address of the master node.

Set the public url configuration and credentials for dex and oidc,

\`\`\`bash
juju config dex-auth public-url=https://example.com
juju config oidc-gatekeeper public-url=https://example.com

juju config dex-auth static-username=username
juju config dex-auth static-password=password
\`\`\`


Setup cluster issuer,
![Cluster Issuer](https://d31ofspcjjwsfk.cloudfront.net/deploying-kubeflow-using-k8s/cluster-issuer.png)


Setup ingress,
![Ingress](https://d31ofspcjjwsfk.cloudfront.net/deploying-kubeflow-using-k8s/ingress.png)


Open your web browser and type in the domain address that you bought, this should direct you to the dex login page. 


Login, and you should be directed to kubeflow

![Ingress](https://d31ofspcjjwsfk.cloudfront.net/deploying-kubeflow-using-k8s/kubeflow.png)


Perhaps, in my next post, I will share how to run an Kubeflow pipeline. Thats all for now :)

See you in the next one !
`;