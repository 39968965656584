import List from '../components/list';
import { ArticleType } from '../types';
import { useNavigate } from 'react-router-dom';

type HomePageProps = {
  articles : {
    articles_2023: { [key: string]: ArticleType },
    articles_2024: { [key: string]: ArticleType },
  }
}

// const HomePage = ({ articles_2023, articles_2024 } : HomePageProps) => {
const HomePage = ({ articles }: HomePageProps) => {
  const { articles_2023, articles_2024 } = articles;
  const navigate = useNavigate()
  const handleOnClick = (value: string) => {
    return navigate(value)
  }
  return (
    <div className = 'flex justify-start flex-col relative flex-1'>
       <div className="pb-8">
        <h1 className="text-3xl"><b>2024</b></h1>
        <ul className="list-square">
          {Object.keys(articles_2024).map((key) => (
            <List article={articles_2024[key]} key={key} handleOnClick={handleOnClick}/>
          ))}
        </ul>
      </div>
      <div>
        <h1 className="text-3xl"><b>2023</b></h1>
        <ul className="list-square">
          {Object.keys(articles_2023).map((key) => (
            <List article={articles_2023[key]} key={key} handleOnClick={handleOnClick}/>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default HomePage;