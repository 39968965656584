import { ArticleType } from '../types';

type ListProps = {
  article: ArticleType
  handleOnClick: (id: string) => void;
}

const List = ({ article, handleOnClick } : ListProps) => {
  return (
    <li className="text-xl py-2">
      <span onClick={() => handleOnClick(article.id)} className='text-rust font-bold cursor-pointer'>{article.title}</span>
      {article.shortDescription}
    </li>
  );
}

export default List;
