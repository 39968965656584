
const Footer = () => {
  return (    
  <div className="py-6 black m-auto">
    © 2024. All Rights Reserved.
  </div>
  );
}

export default Footer;
